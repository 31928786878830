import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { NewDiversificationModule } from '@app/core/new-diversification/api.module';
import { PlanTradUnsignedGuard } from '@app/plan-blocked.guard';
import { ProfileUpdatedGuard } from '@app/profile-updated.guard';
import { UnsignedContractGuard } from '@app/unsigned-contract.guard';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthenticationModule } from './authentication/authentication.module';
import { BoletoModule } from './boleto/boleto.module';
import { BpApiModule } from './bpApi/bpApi.module';
import { CheckupBffModule } from './checkup-bff/checkup-bff.module';
import { ContributionManagerBffModule } from './contribution-manager-bff/new-contribution-bff.module';
import { DecumulationApiModule } from './decumulation-api/decumulation-api.module';
import { DecumulationBffModule } from './decumulation-bff/decumulation-bff.module';
import { DepartureDateBffModule } from './departure-date-bff/departure-bff.module';
import { DiversificationBffModule } from './diversification-api/diversificationBff.module';
import { ErrorsModule } from './errors';
import { ExtraContributionBffModule } from './extra-contribution-bff/extra-contribution-bff.module';
import { FeatureFlagGuard } from './feature-flag/feature-flag.guard';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { LGPDBffModule } from './lgpd-api/lgpdBff.module';
import { LoginModule } from './login/login.module';
import { NewContributionBffModule } from './new-contribution-bff/new-contribution-bff.module';
import { ReallocationBffModule } from './reallocation-api/reallocationBff.module';
import { UpsellingBffModule } from './upselling-api/upsellingBff.module';
import { ValidationBffModule } from './validation-bff/validation-bff.module';

@NgModule({ exports: [],
    declarations: [], imports: [CommonModule,
        ErrorsModule,
        BpApiModule,
        UpsellingBffModule,
        ReallocationBffModule,
        DiversificationBffModule,
        AuthenticationModule,
        LoginModule,
        BoletoModule,
        CheckupBffModule,
        DecumulationApiModule,
        DecumulationBffModule,
        LGPDBffModule,
        CheckupBffModule,
        DepartureDateBffModule,
        ExtraContributionBffModule,
        ValidationBffModule,
        NewContributionBffModule,
        ContributionManagerBffModule,
        NewDiversificationModule], providers: [
        AuthGuard,
        FeatureFlagGuard,
        FeatureFlagService,
        ProfileUpdatedGuard,
        UnsignedContractGuard,
        PlanTradUnsignedGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
